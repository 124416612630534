import React from 'react';
import { Box, Icon, makeStyles } from '@popmenu/common-ui';
import { ExternalLink } from '@popmenu/web-icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isSameDay, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { openVipV2Modal } from '../../shared/ModalActions';

import { withCurrentSession } from '../../shared/CurrentSessionProvider';
import { withRestaurant } from '../../utils/withRestaurant';
import { classNames } from '../../utils/withStyles';
import { readableFontColor } from '../../utils/colors';
import { nl2br } from '../../utils/utils';
import { formatTime } from '../../utils/time';
import { themeShape, withTheme } from '../../utils/withTheme';

import CustomImg from '../../shared/CustomImg';
import { AH } from '../shared/AccessibleHeading';
import { eventFormatDate, recurringDescription } from '../../shared/Calendar/calendarUtils';

const useStyles = makeStyles(() => ({
  eventLink: {
    bottom: props => (props.externalLinkUrl ? 100 : 0),
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
}));

const CalendarEventCard = ({ displayEventTags, event, getNextAvailableDate, isSecret, signUpHeadingCta, theme }) => {
  const dispatch = useDispatch();
  const styles = {
    customSectionContentFont: {
      ...theme.customSectionContentFont,
    },
    customSectionHeaderFont: {
      ...theme.customSectionHeaderFont,
    },
  };
  const classes = useStyles({ externalLinkUrl: event.externalLinkUrl });
  const eventTags = event.selectedTags.map((selectedTag) => {
    const tag = selectedTag.eventTag;
    if (!displayEventTags || !tag) {
      return null;
    }
    return (
      <div
        key={tag.id}
        className={classNames('pm-calendar-event-tag', isSecret ? 'pm-secret-tags' : null)}
        style={{
          backgroundColor: tag.color,
          color: readableFontColor(tag.color),
        }}
      >
        {tag.name}
      </div>
    );
  });

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const renderSecretMessage = () => {
    if (isSecret) {
      return (
        <div>
          <div onClick={() => dispatch(openVipV2Modal())} className="pm-secret-bg" />
          <span className="pm-secret-message">
            <button className="a" type="button">
              <FormattedMessage
                id="consumer.calendar_event_card.secret_event_cta"
                defaultMessage="{signUpHeadingCta}{br}to view our secret event!"
                values={{
                  br: <br />,
                  signUpHeadingCta,
                }}
              />
            </button>
          </span>
        </div>
      );
    }
    return null;
  };
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  const eventDateTile = (nextAvailableDate) => {
    const month = eventFormatDate(nextAvailableDate, 'MMM');
    const day = eventFormatDate(nextAvailableDate, 'd');

    return (
      <div className="pm-calendar-event-content-left">
        <span className="calendar-event-month" data-testid="upcoming-month">{month}</span>
        <span className="calendar-event-date" data-testid="upcoming-day">{day}</span>
      </div>
    );
  };

  const eventDate = () => {
    if (event.isRecurring && (!event.endAt || event.hideStartEndDate)) {
      return null;
    }
    if (event.endAt) {
      if (!isSameDay(parseISO(event.startAt), parseISO(event.endAt))) {
        return `${[eventFormatDate(event.startAt, 'EEE, MMM dd'), formatTime(event.startTime)].filter(Boolean).join(', ')} - ${[eventFormatDate(event.endAt, 'EEE, MMM dd'), formatTime(event.endTime)].filter(Boolean).join(', ')}`;
      }
    }
    if (event.endTime) {
      return `${[eventFormatDate(event.startAt, 'EEE, MMM dd'), formatTime(event.startTime)].filter(Boolean).join(', ')} - ${[formatTime(event.endTime)].filter(Boolean).join(', ')}`;
    }
    return [eventFormatDate(event.startAt, 'EEE, MMM dd'), formatTime(event.startTime)].filter(Boolean).join(', ');
  };

  const recurringEventDescription = () => (
    <FormattedMessage
      id="consumer.calendar_event_card.secret_event_cta"
      defaultMessage={recurringDescription(event, true)}
    />
  );

  const eventDescription = (text) => {
    let content = text;
    if (content.length >= 180) {
      content = `${text.substring(0, 178)}...`;
    }
    return nl2br(content);
  };

  const nextAvailableDate = getNextAvailableDate(event);

  const externalLinkText = event.externalLinkText || 'Event Link';

  return (
    <div className={classNames('pm-calendar-event', isSecret ? 'pm-secret' : null, 'pm-calendar-event-card')}>
      <Link to={`/events/${event.slug}`} className={classes.eventLink} aria-label={event.name} />
      {event.photoUrl && (
        <Box component="a" aria-hidden="true" className="pm-calendar-event-pic-link">
          <CustomImg src={event.photoUrl} alt={event.photoAlt ? event.photoAlt : event.name} className="pm-calendar-event-pic" />
        </Box>
      )}
      {eventTags}
      <div className={classNames('pm-calendar-event-content', isSecret ? 'pm-secret-content' : null)}>
        {eventDateTile(nextAvailableDate)}
        <div className="pm-calendar-event-content-right">
          <AH variant="h4" className="calendar-event-name" style={styles.customSectionHeaderFont}>
            <Box component="a">{event.name}</Box>
          </AH>
          <p style={styles.customSectionContentFont} aria-label={'Event date and time'}>{eventDate()}</p>
          {event.isRecurring && <p style={styles.customSectionContentFont}>{recurringEventDescription()}</p>}
          {event.description && (
            <p style={styles.customSectionContentFont}>
              {eventDescription(event.description)}
            </p>
          )}
          {event.externalLinkUrl && (
            <a
              href={event.externalLinkUrl}
              rel="noopener"
              target="_blank"
              aria-label={`${externalLinkText} opens in a new tab`}
            >
              {externalLinkText} <Icon icon={ExternalLink} />
            </a>
          )}
        </div>
      </div>
      {renderSecretMessage()}
    </div>
  );
};

CalendarEventCard.propTypes = {
  displayEventTags: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    color: PropTypes.string,
    description: PropTypes.string,
    endAt: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    photoAlt: PropTypes.string,
    photoUrl: PropTypes.string,
    slug: PropTypes.string,
    startAt: PropTypes.string,
  }).isRequired,
  isSecret: PropTypes.bool.isRequired,
  signUpHeadingCta: PropTypes.string.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withCurrentSession,
  withTheme,
  withRestaurant,
  mapProps(({ restaurant, currentSession, ...props }) => ({
    ...props,
    isSecret: props.event.isSecret && !currentSession.user,
    signUpHeadingCta: restaurant.signUpHeadingCta,
  })),
)(CalendarEventCard);
