import { formatDate, formatTime, toDaysOfWeek, type DirtyDate } from '../../utils/time';
import type { CalendarEvent } from '../../libs/gql/types';

export const recurringDescription = (calendar: CalendarEvent, useAbbreviation: boolean = false) => {
  const { isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday } = calendar;

  const isAnyOtherWeekday = isMonday || isTuesday || isWednesday || isThursday || isFriday;
  const isAnyOtherWeekend = isSaturday || isSunday;
  const isWeekend = isSaturday && isSunday;
  const isWeekday = isMonday && isTuesday && isWednesday && isThursday && isFriday;
  const isEveryDay = isWeekend && isWeekday;

  let timeFrame;
  if (calendar.endTime) {
    timeFrame = `at ${formatTime(calendar.startTime)} - ${formatTime(calendar.endTime)}`;
  } else {
    timeFrame = `at ${formatTime(calendar.startTime)}`;
  }

  const timeUsed = calendar.isAllDay ? '' : timeFrame;

  if (isEveryDay) return `Everyday ${timeUsed}`.trim();
  if (!isAnyOtherWeekend && isWeekday && !isWeekend) return `Weekdays ${timeUsed}`.trim();
  if (!isAnyOtherWeekday && !isWeekday && isWeekend) return `Weekends ${timeUsed}`.trim();

  return `Weekly ${toDaysOfWeek(calendar, useAbbreviation)} ${timeUsed}`.trim();
};

export const eventFormatDate = (date: DirtyDate, format: string = 'M/d/yyyy') => (
  // Default utc is false
  formatDate(date, format)
);

export const eventDateDescription = (calendar: CalendarEvent) => {
  if (!calendar.startAt) {
    return null;
  }

  const startDate = eventFormatDate(calendar.startAt);
  const startTime = formatTime(calendar.startTime) || '';
  const endDate = eventFormatDate(calendar.endAt);
  const endTime = formatTime(calendar.endTime) || '';

  if (calendar.isAllDay) {
    if (calendar.endAt) {
      return `${startDate} - ${endDate}`;
    }
    return startDate;
  }

  if (calendar.endAt) {
    return `${startDate} ${startTime} - ${endDate} ${endTime}`;
  }

  return `${startDate} ${startTime}`;
};
