import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@popmenu/common-ui';

import { classNames, withStyles } from '../../../utils/withStyles';
import calendarEventToggleStyles from './styles';
import Grid from '../../../shared/Grid';

const BUTTON_VIEWS = [
  {
    key: 'timeline',
    text: 'Upcoming Events',
  },
  {
    key: 'calendar',
    text: 'Calendar',
  },
];

const CalendarEventToggle = ({ calendarEventView, classes, handleViewToggle }) => (
  <Grid
    container
    direction="row"
    justify="flex-end"
    alignItems="center"
  >
    <Grid item>
      <ButtonGroup
        aria-label="Toggle calendar view"
        className={classNames(classes.buttonGroup, 'pm-calendar-buttons')}
        color="primary"
        variant="outlined"
      >
        {BUTTON_VIEWS.map(({ key, text }) => (
          <Button
            key={key}
            className={classNames(
              classes.button,
              calendarEventView === key ? classes.buttonSelected : classes.buttonNotSelected,
            )}
            onClick={() => handleViewToggle(key)}
          >
            {text}
          </Button>
        ))}
      </ButtonGroup>
    </Grid>
  </Grid>
);

CalendarEventToggle.propTypes = {
  calendarEventView: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleViewToggle: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
};

export default withStyles(calendarEventToggleStyles)(CalendarEventToggle);
