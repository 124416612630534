import { readableFontColor } from '../../../utils/colors';

export default theme => ({
  buttonGroup: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  buttonNotSelected: {
    color: readableFontColor('#FFFFFF', theme.palette.primary.main),
  },
  buttonSelected: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.75) !important',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.75) !important',
    color: 'black !important',
    fontWeight: 'bold',
  },
});
