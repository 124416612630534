import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { addDays, addYears, addSeconds, closestTo, differenceInDays, eachDayOfInterval, getISODay, isBefore, isSameDay, parseISO, setHours, setMinutes, startOfDay, startOfMonth, subDays } from 'date-fns';

import { compose, mapProps } from '@shakacode/recompose';
import { withCurrentSession } from '../../shared/CurrentSessionProvider';

import { inGroupsOf, sortByKey } from '../../utils/arrays';
import { formatDate } from '../../utils/time';

import Calendar from '../../shared/Calendar/Calendar.imports-loadable';
import CalendarEventCard from './CalendarEventCard';
import CalendarEventToggle from './CalendarEventToggle';
import Loading from '../../shared/Loading';
import { AH } from '../shared/AccessibleHeading';
import Query from '../../shared/Query';
import customPageCalendarSectionQuery from '../../libs/gql/queries/custom_pages/customPageCalendarSectionQuery.gql';
import customPageCalendarSectionDraftQuery from '../../libs/gql/queries/custom_pages/customPageCalendarSectionDraftQuery.gql';

class CalendarEventsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calendarEventView: props.calendarEventView,
      referenceDates: {
        rangeStartAt: props.calendarEventView === 'calendar' ? startOfMonth(new Date()) : startOfDay(new Date()),
      },
      renderView: false,
    };
    this.handleViewToggle = this.handleViewToggle.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
    this.reloadEventsList = this.reloadEventsList.bind(this);
  }

  // Delay rendering so that there are not SSR inconsistencies across timezones
  componentDidMount() {
    // Set calendarEventView if window size is mobile
    if (window) {
      const isMobileView = window.innerWidth <= 500;
      if (isMobileView) {
        this.setState({ calendarEventView: 'timeline', renderView: true });
      } else {
        const calendarEventView = sessionStorage?.getItem('calendarEventsView') || this.state.calendarEventView;
        if (calendarEventView === 'calendar') {
          this.setState({
            calendarEventView,
            referenceDates: this.getCalendarReferenceDates(),
            renderView: true,
          });
        } else {
          this.setState({
            calendarEventView,
            renderView: true,
          });
        }
      }
    }
  }

  onEventClick({ event }) {
    this.props.history.push(`/events/${event.extendedProps.slug}`);
  }

  handleViewToggle(calendarEventView) {
    sessionStorage?.setItem('calendarEventsView', calendarEventView);
    if (calendarEventView === 'calendar') {
      // Sets the range to allow only one month of content with some gap
      this.setState({ calendarEventView, referenceDates: this.getCalendarReferenceDates() });
    } else {
      const firstDay = startOfDay(new Date());
      const referenceDates = {
        rangeStartAt: firstDay,
      };
      this.setState({ calendarEventView, referenceDates });
    }
  }

  getCalendarReferenceDates() {
    const firstDay = Date.parse(sessionStorage?.getItem('calendarEventsViewStartAt')) || startOfMonth(new Date());
    return {
      rangeEndAt: addDays(firstDay, 45),
      rangeStartAt: subDays(firstDay, 10),
    };
  }

  // Determine if calendarEventSelectedLocations matches on any given locations
  canShowForLocation(calendarEvent) {
    if (!calendarEvent || !calendarEvent.calendarEventSelectedLocations || calendarEvent.calendarEventSelectedLocations.length === 0) {
      return false;
    }
    return calendarEvent.calendarEventSelectedLocations.some((selectedLocation) => {
      if (selectedLocation.isEnabled && this.props.locations.some(location => location.id === selectedLocation.locationId)) {
        return true;
      }
      return false;
    });
  }

  invalidCalendarEvent(calendarEvent, startDate = null, endDate = null) {
    const today = startOfDay(new Date());
    const yesterday = addDays(new Date(), -1);
    const oneYearFromStart = addYears(today, 1);
    const calStart = startDate ? parseISO(startDate) : today;
    const calEnd = endDate ? parseISO(endDate) : oneYearFromStart;

    // Exclude events that are not in the locations array
    if (!this.canShowForLocation(calendarEvent)) {
      return true;
    }

    // Exclude secret events from the calendar view if the user is not logged in
    if (!this.props.isLoggedIn && calendarEvent.isSecret && this.state.calendarEventView === 'calendar') {
      return true;
    }

    // RECURRING EVENT
    if (calendarEvent.isRecurring) {
      const startAt = isBefore(parseISO(calendarEvent.startAt), calStart) ? calStart : parseISO(calendarEvent.startAt);
      if (isBefore(calEnd, startAt)) {
        return true;
      }

      const endAt = (!calendarEvent.endAt || isBefore(calEnd, parseISO(calendarEvent.endAt))) ? calEnd : parseISO(calendarEvent.endAt);
      if (isBefore(endAt, startAt)) {
        return true;
      }
    }

    // NON-RECURRING EVENT
    // Skip past events for timeline
    if (!calendarEvent.isRecurring && this.state.calendarEventView === 'timeline' && isBefore(parseISO(calendarEvent.endAt || calendarEvent.startAt), yesterday)) {
      return true;
    }

    return false;
  }

  reloadEventsList(dateInfo) {
    this.setState({
      referenceDates: { rangeEndAt: dateInfo.end, rangeStartAt: dateInfo.start },
    });

    // Calendar component returns the last days of previous month
    sessionStorage?.setItem('calendarEventsViewStartAt', startOfMonth(addDays(dateInfo.start, 10)));
  }

  // Build a list of calendar event instances:
  // - Recurring events add multiple instances until the endAt day or 60 days
  // - Past events are only included for the calendar view
  calendarEventInstances(calendarEvents = [], startDate = null, endDate = null) {
    const eventsList = [];
    const today = startOfDay(new Date());
    const ninetyDaysFromStart = addDays(today, 90);
    const calStart = startDate ? parseISO(startDate) : today;
    const calEnd = endDate ? parseISO(endDate) : ninetyDaysFromStart;
    calendarEvents.forEach((calendarEvent) => {
      if (this.invalidCalendarEvent(calendarEvent, startDate, endDate)) {
        return;
      }

      if (calendarEvent.isRecurring) {
        // RECURRING EVENT
        const startAt = isBefore(parseISO(calendarEvent.startAt), calStart) ? calStart : parseISO(calendarEvent.startAt);
        const endAt = (!calendarEvent.endAt || isBefore(calEnd, parseISO(calendarEvent.endAt))) ? calEnd : parseISO(calendarEvent.endAt);

        // Map recurring days of week to ISO days
        const recurringDaysToISO = {
          isFriday: 5,
          isMonday: 1,
          isSaturday: 6,
          isSunday: 7,
          isThursday: 4,
          isTuesday: 2,
          isWednesday: 3,
        };

        // Iterate over the interval from the start date
        eachDayOfInterval({ end: endAt, start: startAt }).forEach((day) => {
          // Skip excluded days
          if (calendarEvent.calendarEventRecurringExceptions.some(exception => isSameDay(parseISO(exception.exceptionAt), day))) {
            return;
          }
          Object.keys(recurringDaysToISO).forEach((key) => {
            if (calendarEvent[key] && getISODay(day) === recurringDaysToISO[key]) {
              let dayWithTime;
              if (calendarEvent.startTime) {
                const h = Math.floor(calendarEvent.startTime / 3600);
                const m = Math.floor(calendarEvent.startTime % 3600 / 60);
                dayWithTime = setHours(startOfDay(day), h);
                dayWithTime = setMinutes(dayWithTime, m);
              } else {
                dayWithTime = day;
              }
              // Push event instance on to stack
              eventsList.push({
                ...calendarEvent,
                endAt: null,
                startAt: formatDate(dayWithTime, 'ISO'),
              });
            }
          });
        });
      } else {
        const startAt = parseISO(calendarEvent.startAt);
        let dayWithTime;
        if (calendarEvent.startTime) {
          const h = Math.floor(calendarEvent.startTime / 3600);
          const m = Math.floor(calendarEvent.startTime % 3600 / 60);
          dayWithTime = setHours(startOfDay(startAt), h);
          dayWithTime = setMinutes(dayWithTime, m);
        } else {
          dayWithTime = startAt;
        }
        // Push event on to stack
        eventsList.push({
          ...calendarEvent,
          startAt: formatDate(dayWithTime, 'ISO'),
        });
      }
    });
    if (this.props.maxCalendarEventsDisplayed && this.state.calendarEventView === 'timeline') {
      return sortByKey(eventsList, 'startAt').splice(0, this.props.maxCalendarEventsDisplayed);
    }
    return sortByKey(eventsList, 'startAt');
  }

  getNextAvailableDate = (event) => {
    const today = startOfDay(new Date());
    const oneYearFromStart = addYears(today, 1);
    const startAt = parseISO(event.startAt);
    const endAt = event.endAt ? parseISO(event.endAt) : oneYearFromStart;

    if (!event.endAt && !event.isRecurring) {
      return startAt;
    }

    // Map excluded days to ISO
    const excludedDays = event.calendarEventRecurringExceptions.map(exception => parseISO(exception.exceptionAt));

    // Recurring events
    if (event.isRecurring) {
      const dayOfWeekMap = {
        isFriday: 5,
        isMonday: 1,
        isSaturday: 6,
        isSunday: 7,
        isThursday: 4,
        isTuesday: 2,
        isWednesday: 3,
      };

      // Return available days as numbers: 1 for Monday, 2 for Tuesday, etc.
      const availableDays = [
        'isMonday',
        'isTuesday',
        'isWednesday',
        'isThursday',
        'isFriday',
        'isSaturday',
        'isSunday',
      ].filter(day => event[day]).map(day => dayOfWeekMap[day]);

      // Find next available days by day of week starting at startAt and filter out excluded days
      const availableDaysWithinWeek = [1, 2, 3, 4, 5, 6, 7].filter(day => availableDays.includes(day));

      const daysToLookAt = Math.abs(differenceInDays(endAt, startAt));
      let offsetDaysArray = Array.from(Array(daysToLookAt).keys());
      if (isBefore(startOfDay(today), startOfDay(startAt))) {
        offsetDaysArray = offsetDaysArray.map(plusDay => plusDay + differenceInDays(startOfDay(startAt), startOfDay(today)));
      }
      const availableDates = offsetDaysArray.map(day => addDays(today, day))
        .filter(date => availableDaysWithinWeek.includes(getISODay(date))) // Filter only available days of the week
        .filter(date => isBefore(today, date) || isSameDay(today, date)) // Filter only dates that are on or after today
        .filter(date => !excludedDays.some(excludedDate => isSameDay(excludedDate, date))); // Filter out excluded dates

      const closestAvailableDate = closestTo(today, availableDates);

      if (isBefore(endAt, closestAvailableDate)) {
        return null;
      } else if (isBefore(closestAvailableDate, startAt)) {
        return startAt;
      } else {
        return closestAvailableDate;
      }
    }

    // Non-recurring events
    if (!isSameDay(startAt, endAt)) {
      if (isBefore(endAt, startAt)) {
        return null;
      }
      const availableDates = eachDayOfInterval({ end: endAt, start: startAt }).filter(date => !excludedDays.some(excludedDate => isSameDay(excludedDate, date)));
      const closestAvailableDate = closestTo(today, availableDates);
      if (isBefore(endAt, closestAvailableDate)) {
        return null;
      } else if (isBefore(closestAvailableDate, startAt)) {
        return startAt;
      } else {
        return closestAvailableDate;
      }
    } else {
      return startAt;
    }
  };

  timelineEventInstances(calendarEvents, startDate = null, endDate = null) {
    const validEvents = calendarEvents.filter(calendarEvent => (
      !this.invalidCalendarEvent(calendarEvent, startDate, endDate) &&
      !!this.getNextAvailableDate(calendarEvent)),
    );
    return validEvents.sort((a, b) => {
      const aNextAvailable = this.getNextAvailableDate(a);
      const bNextAvailable = this.getNextAvailableDate(b);
      if (isSameDay(aNextAvailable, bNextAvailable)) {
        return isBefore(a.startTime, b.startTime) ? -1 : 1;
      }
      return isBefore(aNextAvailable, bNextAvailable) ? -1 : 1;
    });
  }

  render() {
    if (!this.state.renderView) {
      return <Loading size="fullscreen-align-top" />;
    }

    return (
      <React.Fragment>
        <CalendarEventToggle
          calendarEventView={this.state.calendarEventView}
          handleViewToggle={this.handleViewToggle}
          restaurantId={this.props.restaurant.id}
        />
        <Query
          query={this.props.draftMode ? customPageCalendarSectionDraftQuery : customPageCalendarSectionQuery}
          variables={{
            ...this.state.referenceDates,
            limit: this.state.calendarEventView === 'timeline' ? 60 : null,
            sectionId: this.props.sectionId,
          }}
        >
          {({ data, loading }) => {
            const section = data?.customPageSection || data?.customPageSectionDraft;
            if (loading || !section) {
              return <Loading size="fullscreen-align-top" />;
            }

            const { upcomingCalendarEvents } = section;
            const timelineEventInstances = this.timelineEventInstances(upcomingCalendarEvents);
            const timelineEventDisplayLimit = this.props.maxCalendarEventsDisplayed > 0 ? this.props.maxCalendarEventsDisplayed : timelineEventInstances.length + 3;
            const groupCount = 3;
            return (

              <React.Fragment>
                {/* Calendar View */}
                {this.state.calendarEventView === 'calendar' && (
                <Calendar
                  events={(info, successCallback) => {
                    successCallback(this.calendarEventInstances(upcomingCalendarEvents, info.startStr, info.endStr).map((event) => {
                      const start = parseISO(event.startAt); // Already includes startTime as added by calendarEventInstances
                      let end = null;
                      if (event.endAt) {
                        end = parseISO(event.endAt);
                        if (event.endTime) {
                          end = addSeconds(end, event.endTime);
                        }
                      } else if (event.endTime && event.startTime) {
                      // the difference in seconds is the correct amount of seconds to add to the start day
                        end = addSeconds(start, event.endTime - event.startTime);
                      }

                      return {
                        allDay: event.isAllDay,
                        end: formatDate(end, 'ISO'),
                        extendedProps: {
                          description: event.description,
                          photo: event.photo,
                          slug: event.slug,
                          url: event.externalLinkUrl,
                        },
                        id: event.id,
                        start: formatDate(start, 'ISO'),
                        title: event.name,
                      };
                    }));
                  }}
                  onEventClick={this.onEventClick}
                  onDateChange={this.reloadEventsList}
                  restaurant={this.props.restaurant}
                  initialDate={addDays(this.state.referenceDates.rangeStartAt, 10)}
                />
                )}

                {/* Timeline View */}
                {this.state.calendarEventView === 'timeline' && timelineEventInstances.length === 0 && (
                <div className="text-center">
                  <AH variant="h3">
                    <FormattedMessage
                      id="calendar_events.no_upcoming_events"
                      defaultMessage="No upcoming events"
                    />
                  </AH>
                </div>
                )}
                {this.state.calendarEventView === 'timeline' && timelineEventInstances.length > 0 && (inGroupsOf(timelineEventInstances, 3).map((events, i) => (
                  <div key={i} className="row pm-calendar-events">
                    {events.map((event, j) => (
                      <div key={j} className="col-md-4 col-xs-12 pm-calendar-event-card-wrapper">
                        <CalendarEventCard
                          event={event}
                          displayEventTags={this.props.restaurant.displayEventTags}
                          getNextAvailableDate={this.getNextAvailableDate}
                        />
                      </div>
                    ))}
                  </div>
                )).slice(0, timelineEventDisplayLimit / groupCount))}
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

CalendarEventsView.defaultProps = {
  maxCalendarEventsDisplayed: null,
};

CalendarEventsView.propTypes = {
  calendarEventView: PropTypes.oneOf(['calendar', 'timeline']).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  maxCalendarEventsDisplayed: PropTypes.number,
  restaurant: PropTypes.shape({
    displayEventTags: PropTypes.bool,
    id: PropTypes.number,
  }).isRequired,
};

export default compose(
  withRouter,
  withCurrentSession,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isLoggedIn: currentSession.user,
  })),
)(CalendarEventsView);
